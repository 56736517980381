.layout {
    $el: &;

    $header-height: 56px;
    $header-height-mobile: 48px;
    $sub-header-height: 34px;
    $double-header-height-mobile: calc(2 * $header-height-mobile);

    $margin-top-header: calc($header-height + 1px);
    $margin-top-double-header: calc($double-header-height-mobile + 1px);
    $margin-top-header-mobile: calc($header-height-mobile + 1px);
    $double-header-subheader-height: calc($margin-top-double-header + $sub-header-height);

    $full-without-header: calc(100vh - $margin-top-header);
    $full-without-header-mobile: calc(100vh - $margin-top-header-mobile);
    $full-without-double-header: calc(100vh - $margin-top-double-header);
    $full-without-two-header: calc($full-without-header - $sub-header-height);
    $full-without-two-double-header-mobile: calc($full-without-double-header - $sub-header-height);


    &--content {
        display: flex;
        margin-top: $margin-top-double-header;
        flex-direction: column-reverse;
        height: $full-without-double-header;

        @include breakpoints(sm) {
            flex-direction: row;
            overflow: auto;
            margin-top: $margin-top-header;
            height: $full-without-header;
        }

        .layout--page-favorites & {
            margin-top: $margin-top-double-header;
            height: $full-without-double-header;

            @include breakpoints(sm) {
                margin-top: $margin-top-header;
                height: $full-without-header;
            }
        }

        .layout--page-product &,
        .layout--page-model &,
        .layout--page-swipe.layout--only-topbottom & {
            margin-top: $margin-top-header-mobile;
            height: $full-without-header-mobile !important;

            @include breakpoints(sm) {
                margin-top: $margin-top-header;
                height: $full-without-header !important;
            }
        }

        &--with-subheader {
            @extend .layout--content;
            margin-top: $double-header-subheader-height;
            height: $full-without-two-double-header-mobile;

            @include breakpoints(sm) {
                margin-top: calc($margin-top-header + $sub-header-height);
                height: $full-without-two-header;
            }

            .layout--page-favorites & {
                margin-top: $margin-top-double-header;
                height: calc(100vh - $margin-top-double-header);
    
                @include breakpoints(sm) {
                    margin-top: $margin-top-header;
                    height: $full-without-header;
                }
            }

            .layout--page-product &,
            .layout--page-model &,
            .layout--page-swipe.layout--only-topbottom & {
                margin-top: $margin-top-header-mobile;
                height: $full-without-header-mobile !important;
    
                @include breakpoints(sm) {
                    margin-top: calc($header-height + 1px);
                    height: $full-without-header !important;
                }
            }
        }
    }

    &--container {
        background-color: $lightcolor;
        height: auto;
    }

    &--header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background-color: white;
        position: fixed;
        z-index: 2;
        height: auto;
        width: 100%;
        padding: 0px;
        line-height: initial;
    }

    &--header-title {
        display: flex;
        align-items: center;

        &-separator {
            border-right: 1px solid $lightcolor;
        }
    }

    &--header-main-container {
        width: 100%;
        overflow-x: hidden;
    }

    &--header-top-container {
        display: flex;
        flex-direction: column-reverse;
        
        @include breakpoints(sm) {
            flex-direction: row;
        }
    }

    &--header-right {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px;
        min-height: $header-height-mobile;

        @include breakpoints(sm) {
            min-height: $header-height;
        }
    }

    &--header-button-container {
        display: flex;
        flex: 5;
        justify-content: flex-end;
    }

    &--header-left {
        display: flex;
        flex-direction: row;
        align-items: center;
        min-height:  $header-height-mobile;
        height: 100%;
        width: 100%;
        overflow-x: hidden;

        .ant-tabs-top {
            height: 100%;
            min-width: 80px;
        }

        .ant-tabs-top .ant-tabs-nav {
            height: 100%;
            margin: 0;
        }

        @include breakpoints(sm) {
            min-height: $header-height;
        }

        .layout--page-product &,
        .layout--page-model &,
        .layout--page-swipe.layout--only-topbottom & {
            display: none;

            @include breakpoints(sm) {
                display: flex;
            }
        }
    }

    &--header-left-content {
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;
        overflow-x: hidden;
    }

    &--header-left-content-menu {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex: 1;
        height: $header-height-mobile;
        justify-content: space-between;
        min-width: 0;

        @include breakpoints(sm) {
            height: $header-height;
            justify-content: flex-start;
        }

        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
            text-shadow: 0 0 1px currentColor;
        }
    }

    &--header-filter-button {
        width: 38px;
        height: 38px;
        font-size: 38px;
        line-height: 38px;
        margin-right: -4px;
        padding: 0;

        &>.anticon {
            font-size: 38px;
        }
    }

    &--header-filter-label {
        margin-left: 8px;
        display: none;

        @include breakpoints(smd) {
            display: block;
        }
    }

    &--swipe-content {
        align-items: center;
        height: calc(100vh - $double-header-height-mobile - 56px);

        .layout--page-swipe.layout--only-topbottom & {
            height: calc($full-without-header-mobile - 62px);
        }

        @include breakpoints(sm) {
            height: calc($full-without-header - 31px);

            .layout--title {
                display: none;
            }
        }

        &--with-subheader {
            @extend .layout--swipe-content;
            height: calc(100vh - $double-header-height-mobile - 56px - $sub-header-height);

            @include breakpoints(sm) {
                height: calc($full-without-header - $sub-header-height - 30px);
            }
        }
    }

    &--right-content {
        flex: 1;
        display: none;
        background-color: $lightcolor--lighter;

        @include breakpoints(sm) {
            display: block;
            overflow-y: auto;
            height: $full-without-header;
            padding: 16px 16px 0;

            .layout--title {
                display: none;
            }
        }

        &--with-subheader {
            @extend .layout--right-content;

            @include breakpoints(sm) {
                height: $full-without-two-header;

                .layout--page-product &,
                .layout--page-favorites &,
                .layout--page-model & {
                    height: $full-without-header;
                }
            }

        }
    }

    &--with-pageheader {
        height: $full-without-header;
        overflow: auto;
        padding: rem(20px);

        @include breakpoints(sm) {
            height: $full-without-header;
        }
    }

    &--with-header {
        height: $full-without-header;
        overflow-y: auto;
    }

    &--left-content {
        padding: 16px 16px 0;
        background-color: white;
        flex: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: $full-without-two-double-header-mobile;
        overflow-y: auto;
        height: $full-without-two-double-header-mobile;

        @include breakpoints(sm) {
            min-height: $full-without-header;
            height: $full-without-header;
        }

        .layout--page-model & {
            padding: 0 16px 0;
            flex: none;
            height: $full-without-header-mobile;

            @include breakpoints(sm) {
                height: $full-without-header;
                flex: 2;
            }

            .model--container {
                @include breakpoints(sm) {
                    flex: 2;
                }
            }
        }

        &--with-subheader {
            @extend .layout--left-content;
            min-height: $full-without-two-double-header-mobile;
            height: $full-without-two-double-header-mobile;

            @include breakpoints(sm) {
                min-height: $full-without-two-header;
                height: $full-without-two-header;
            }

            .layout--page-product &,
            .layout--page-favorites & {
                height: $full-without-header-mobile;

                @include breakpoints(sm) {
                    height: $full-without-header;
                }
            }

            .layout--page-model & {
                height: $full-without-header-mobile;
    
                @include breakpoints(sm) {
                    height: $full-without-header;
                }
            }
        }
    }

    &--side-left {
        background-color: #f7f7fa;
    }

    &--side-right {
        background-color: $background;
    }

    &--title {
        display: none;

        @include breakpoints(sm) {
            display: flex;
        }

        min-height: 0 !important;
    }

    &--headroom {
        background-color: #fff;
        padding-top: 20px;

        @include breakpoints(sm) {
            display: none;
        }

        .sticky {
            top: 65px !important;
            z-index: 1;
        }
    }

    &--poweredby-mobile {
        @include breakpoints(sm) {
            display: none;
        }
    }

    %powered-by {
        color: rgba(0, 0, 0, .25);
        text-align: center;
        margin-top: 32px;
        padding-bottom: 8px;
        font-size: rem(12px);

        a {
            text-decoration: underline;
            color: rgba(0, 0, 0, .25);
        }
    }

    &--poweredby-mobile {
        @extend %powered-by;

        .layout--page-model & {
            margin-top: 0;
        }

        @include breakpoints(sm) {
            display: none;
        }
    }

    &--poweredby-desktop {
        @extend %powered-by;
        display: none;

        @include breakpoints(sm) {
            display: block;
        }
    }
}