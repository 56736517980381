.model {
  $el: &;

  &--container {
    height: 100%;
    position: relative;
  }

  &--empty {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
  }
}
