.card-cart {
  $el: &;

  &--container {
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: rem(180px);
    box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
    box-sizing: border-box;
    padding: rem(16px);
    border-radius: 0;
    margin-bottom: rem(16px);
    overflow: hidden;
    @include breakpoints(sm) {
      flex-direction: row;
    }

    &-swipe {
      background-color: white;
      @include breakpoints(sm) {
        border: none;
      }
    }

  }

  &--image {
    width: rem(155px);
    max-width: 100%;
    padding: 16px;
    
    & > img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &--content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 0;

    @include breakpoints(mdl) {
      padding-left: 16px;
    }
  }

  &--col {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;

    @include breakpoints(mdl) {
      margin-bottom: 0;
    }
  }

  &--flex {
    display: flex;
  }

  &--size {
    flex: 1;
    align-items: center;
    display: flex;
    padding-top: 8px;
  }

  &--title-mobile {
    @include breakpoints(sm) {
      display: none;
    }
  }

  &--title-desktop {
    display: none;
    @include breakpoints(sm) {
      display: block;
    }
  }
}
