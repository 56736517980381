.product {
  $el: &;
  position: relative;

  &--container {
    padding: rem(10px);
    height: 100%;
    @include breakpoints(sm) {
      margin-top: rem(35px);
      margin-bottom: rem(35px);
    }
  }

  &--bottom {
    display: flex;
    min-height: rem(100px);
    height: rem(100px);
    width: 100%;
  }

  &--brand {
    @extend %title-h2;
    font-weight: normal !important;
  }

  &--title {
    @extend %title-h2;
  }

  &--price {
    font-weight: bold;
    font-size: rem(24px);
    margin-bottom: rem(16px);

    &--promotion {
      font-weight: bold;
      font-size: rem(13px);
      margin-bottom: 0;
    }

    &--original {
      text-decoration: line-through;
    }

    &--percent {
      margin-left: rem(12px);
    }
  }

  &--text {
    @extend %text-base;
    font-size: rem(13px);
    margin-bottom: rem(30px);

    @include breakpoints(sm) {
      max-width: 80%;
    }
  }

  &--bottom-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 8px;
    column-gap: 8px;

    @include breakpoints(sm) {
      justify-content: flex-start;
    }

    .ant-select {
      width: rem(247px);
    }

    button {
      @extend %button-medium-base;
      width: rem(247px);
    }
  }

  &--list {
    margin-bottom: rem(20px);
    padding: 0;
    padding-left: rem(20px);

    li {
      @extend %text-base;
      font-size: rem(13px);
      line-height: rem(18px);
    }
  }

  &--left-side {
    @include breakpoints(mdl) {
      padding-right: rem(16px);
    }
  }

  &--right-side {
    display: flex;
    flex-direction: column;
  }

  &--extra-product {
    padding: 0;
    margin-bottom: rem(16px); 
  }
}
