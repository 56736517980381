.icon {
  $el: &;

  &--medium {
    svg {
      height: rem(24px);
      width: rem(24px);
    }
  }

  &--small {
    svg {
      height: rem(14px);
      width: rem(14px);
    }
  }
}
