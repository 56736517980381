.image {
  $el: &;

  &--preview-container {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    overflow-x: hidden;
    margin-bottom: rem(20px);
    .ant-image-mask {
      border-radius: rem(16px);
    }
  }

  &--preview-relative {
    width: 100%;
    display: inline-block;
    position: relative;
    border-radius: 0;
    overflow: hidden;
    & > .ant-image {
      display: block;
    }
  }

  &--preview-carousel {
    cursor: pointer;

    .slick-track {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &--preview {
    width: 100%;
    cursor: pointer;
  }

  &--dot-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: rem(20px);

    & > div {
      margin-right: rem(4px);
    }
  }

  &--dot {
    border-radius: 50%;
    width: 8px;
    height: 8px;
    background-color: #979797;
    cursor: pointer;
  }

  &--dot-active {
    background-color: var(--scrollbar-color);
  }

  &--row {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-bottom: rem(15px);

    @include breakpoints(sm) {
      display: flex;
    }

    .image--minimize {
      margin-bottom: rem(5px);
    }
  }

  &--col {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;

    & > div {
      margin-bottom: rem(10px);
    }
  }

  &--minimize {
    width: rem(54px);
    border-radius: 0;
    overflow: hidden;
    margin: 0 rem(5px);
    cursor: pointer;
  }

  &--white {
    background: $background;
  }

  #{$el}__gif {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 100%;
    width: 100%;
    opacity: 1;
    animation: opacityOn normal forwards step-start;
    animation-delay: 3s;
    & > img {
      width: 450px;
      height: 280px;
      border-radius: 5%;
      transform: scale(0.8);
    }
  }

  @keyframes opacityOn {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes opacityOff {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  #{$el}__chat {
    height: 100%;
    opacity: 0;
    animation: opacityOff 1s normal forwards;
    animation-delay: 0.6s;
  }
}
.anticon-rotate-right,
.anticon-rotate-left {
  display: none;
}
