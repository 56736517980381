.filters {
    $el: &;

    &--head {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 16px;
    }

    &--title {
        @extend %title-h2;
        margin-right: 16px;
        margin-bottom: 0;
    }

    &--container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }

    &--responsive {
        display: flex;
        flex-direction: column;

        &>div {
            margin-bottom: rem(10px);
        }
    }

    &--tab-container {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        margin-bottom: 8px;
    }

    &--tab {
        display: flex;
        flex-direction: row;
        gap: 8px;
        padding: 8px;
        background-color: rgba(0, 0, 0, .05);
        ;
        align-items: center;
    }

    &--mobile {
        display: none;
    }

    &--close-row {
        justify-content: flex-end;
        margin-bottom: 8px;
    }

    .ant-tabs {
        width: 100%;
        align-items: center;
    }
}

.ant-select-selection-search-input {
    font-size: 16px !important;
}