.drawer {
  $el: &;

  &--button {
    width: 100%;
    border: none !important;
  }
}

.ant-drawer-header {
  display: none;
}
.ant-drawer-content,
.ant-drawer-wrapper-body {
  background: #ebebf0;
}
