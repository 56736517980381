.card-carousel {
    $el: &;

    &--container {
        border: none;
        border-radius: 0;
        box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16);
        overflow: hidden;
        width: auto;

        .ant-card-cover {
            margin-top: 0 !important;
            margin-right: 0 !important;
            margin-left: 0 !important;
        }

        .ant-card-body {
            padding: rem(10px);
        }

        margin: 0 16px;

        @include breakpoints(md) {
            margin: 0;
        }
    }

    &--image {
        object-fit: cover;
        margin-bottom: rem(10px);
        width: 100%;
        height: 100%;
        border-radius: rem(16px) rem(16px) 0 0;
    }

    &--title {
        @extend %title-base;
        font-size: rem(20px) !important;
        line-height: rem(25px) !important;
        margin-bottom: rem(8px);

        &::first-letter {
            text-transform: uppercase;
        }
    }

    &--text {
        font-weight: 500 !important;
        text-align: left;
        font-size: rem(12px);
        line-height: rem(16px);
        margin-bottom: rem(12px);

        &::first-letter {
            text-transform: uppercase;
        }
    }

    &--button {
        min-height: rem(33px);
        width: rem(115px);
        border-radius: 8px;
        color: $background;
        border: none;
        margin-bottom: rem(10px);
    }

    &--hide {
        display: none;
    }

    &--swipe {
        justify-content: center;
        align-items: center;
        display: flex;
        height: rem(180px);
        background-color: transparent;
        border: none;
        margin: 0;
    }

    &--swipe-image {
        object-fit: contain;
        margin-bottom: rem(10px);
        width: 100%;
        height: 100%;
        position: absolute;
        pointer-events: none;

        &.swipe-format {
            object-fit: cover;
            margin-bottom: 0;
            width: auto;
        }
    }
}