.text {
  $el: &;

  &--medium {
    @extend %text-base;
    font-size: rem(16px) !important;
  }

  %text-vsmall,
  &--vsmall {
    @extend %text-base;
    font-size: rem(14px) !important;
  }

  &--small {
    @extend %text-base;
    font-size: rem(12px) !important;
  }

  &--tiny {
    @extend %text-base;
    font-size: rem(10px) !important;
    font-weight: 600;
  }

  &--center {
    text-align: center;
  }
}

%text-base {
  font-style: normal !important;
  font-weight: normal !important;
}
