.filters-model {
  $el: &;

  &--head {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: rem(36px);
  }

  &--button {
    width: auto !important;
  }

  &--title {
    @extend %title-h2;
    margin: 0;
    width: auto !important;
  }

  &--content {
    width: 100%;
    & > div {
      margin-bottom: rem(10px);
    }
  }

  &--container {
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
  }
}
