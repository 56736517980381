.catalog {
  $el: &;

  &--container {
    height: auto;
    margin-bottom: rem(32px);
    padding: rem(20px);
    // max-width: 1280px;

    @include breakpoints(sm) {
      z-index: 99;
    }

    @include breakpoints(md) {
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 8px;
        margin-left: 8px;
        background-color: #f2f2f2;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-clip: content-box;
        background-color: var(--scrollbar-color);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }
    }

    @include breakpoints(mdl) {
      padding-left: 6.5%;
      padding-right: 6.5%;
    }

    .override_card_cover_catalog {
      width: 75%;
      min-height: 200px;
      align-items: center;
      justify-content: center;
      display: flex;
    }
    &.catalog--outfit .override_card_cover_catalog {
      width: 100%;
      min-height: 0;
    }

    .override_catalog_grid_column {
      @include breakpoints(mdl) {
        max-width: 33%;
        flex: 0 0 33%;
      }
    }
  }

  &--empty {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
  }

  &--item {
    display: flex;
    justify-content: center;
  }
}
