.modal {

    padding: 0;

    .ant-modal-body {
        display: flex;
        padding-bottom: 0px;
        overflow: auto;
        height: 100vh;

        @include breakpoints(sm) {
            height: auto;
        }
    }

    @media only screen and (max-width: 768px) {
        top: 0;
        margin: 0;

        .ant-modal,
        .ant-modal-content {
            height: 100vh;
            width: 100vw;
            margin: 0;
            top: 0;
        }
    }

    &--carousel-container {
        .slick-slide {
            padding: 0 8px;
        }

        flex: 1;
        margin-top: 16px;
        overflow-y: auto;
    }

    &--container {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow-x: hidden;
        height: calc(100vh - 24px);

        @include breakpoints(sm) {
            height: calc(100vh - 48px);
        }
    }

    &--no-recommendation {
        height: auto;
    }

    &--title {
        padding-bottom: 4px;
        padding-left: 8px;
        border-bottom: 1px lightgrey solid;
    }

    &--button-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 16px 0px;
        gap: 16px;
    }

    &--button {
        flex: 1;
        min-width: 180px;
    }

    &--arrow {
        height: 2rem;
        width: 2rem;
        background: $background  !important;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16);
        display: flex;
        position: fixed;
        top: unset;
        transform: none;

        svg {
            font-size: rem(13px);
            color: var(--scrollbar-color);
        }

        &:before {
            display: none;
        }
    }

    &--left {
        z-index: 999;
        left: 5px;

        @include breakpoints(sm) {
            left: calc(25% + 5px);
        }
    }

    &--right {
        z-index: 999;
        right: 5px;

        @include breakpoints(sm) {
            right: calc(25% + 5px);
        }
    }

    &--image-container {
        margin-bottom: 16px;
    }
}