.button {
    $el: &;

    &--mobile {
        @include breakpoints(sm) {
            display: none !important;
        }
    }

    &--desktop {
        display: none !important;

        @include breakpoints(sm) {
            display: inline-block !important;
        }
    }

    &--outlined {
        @extend %button-medium-base;
        border: 1px solid;
        border-radius: 8px;
        padding: rem(8px) rem(12px) rem(8px) rem(8px);
    }

    &--default {
        @extend %button-medium-base;
        background-color: #000000 !important;
        border: 1px solid;
        border-radius: 8px;
        padding: rem(8px) rem(12px) rem(8px) rem(8px);

        &>span {
            font-weight: 600;
            font-size: rem(14px);
            line-height: rem(24px);
            color: $lightcolor;
        }
    }

    &--underlined {
        display: inline-block;
        cursor: pointer;
        text-decoration: underline;
        padding: 0 10px 0 10px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    &--icon {
        padding: 0 !important;
        margin: 0 !important;
        border: none;
        box-shadow: none;
    }

    &--text {
        @extend %text-vsmall;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        padding: 0;
        padding-right: rem(40px);

        .anticon-left {
            margin-right: rem(8px);
        }
    }

    &--space-r {
        margin-right: rem(8px);
    }

    &--third {
        width: 100%;
        overflow: hidden;

        @include breakpoints(sm) {
            width: auto;
            min-width: 125px;
        }
    }

    >span {
        display: table;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &--change-model {
        width: 150px;
        font-size: 15px;
        position: absolute;
        left: 0;
        white-space: pre-line;

        @include breakpoints(sm) {
            font-size: 14px;
        }
    }

    &--change-model-left {
        left: -150px;
        font-weight: bold;
    }

    &--clear {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30%;
        color: grey;
        cursor: pointer;
        border: none;
        background: transparent !important;
        box-shadow: none;
    }

    &--dark {
        background-color: #000000 !important;
        border: none;

        &>span {
            font-weight: 600;
            color: $lightcolor;
        }
    }

    &--card-dark {
        background-color: #000000 !important;
        width: 100%;
        border: none;

        &>span {
            font-weight: 600;
            font-size: rem(14px);
            line-height: rem(24px);
            color: $lightcolor;
        }
    }

    &--filter {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 16px;
        margin-left: 8px;

        @include breakpoints(sm) {
            margin-right: 0;
            margin-left: 0;
        }

        .ant-badge-count {
            line-height: 13px;
            font-size: 10px;
            border: 1px solid black;
            color: black;
        }
    }
}

%button-medium-base {
    border-radius: rem(8px);
    min-height: rem(40px);
}

%button-small-base {
    border-radius: rem(8px);

    min-height: rem(34px);
}